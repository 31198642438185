import "./Component.css";
import React, { useState } from "react";
import
{ 
    Typography,
    Accordion,
    AccordionSummary,
    AccordionDetails,
    Popover,
    Box,
    Fade
}
from "@mui/material";
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { FcLinux } from "react-icons/fc";
//
import PopupComp from "../popUpBox/PopupComp";

function Misc() {
    const [isOpen, setIsOpen] = useState(false);
    const timeoutValues = [1000, 1300, 1600, 1900, 2200]

    return (
        <section className="misc-container">
            <div className="misc-inner-container">
                <Accordion
                id="misc-accordion"
                sx={{
                    width: "85%",
                    backgroundColor: "black",
                }}
                onChange={() => setIsOpen(prev => !prev)}
                >
                    <AccordionSummary
                    expandIcon={<ExpandMoreIcon style={{color: "white"}}/>}
                    aria-controls="general-information"
                    id="misc-introduction"
                    >
                        <Typography 
                        id="misc-title"
                        variant="h4"
                        >
                            Miscellaneous <FcLinux id="misc-logo"/>
                        </Typography>
                    </AccordionSummary>
                    <AccordionDetails
                    style={{
                        display: "flex",
                        flexDirection: "column",
                    }}
                    >
                        <Box
                        id="box"
                        >
                            {[
                                <PopupComp 
                                name="Basic Pentesting Skills"
                                description={`Nmap, Wireshark, Hydra, smbclient, Powershell Empire, Metasploit, BurpSuite, netcat`}
                                isOpen={isOpen}
                                timeOut={1000}
                                />,
                                "Containerization(Docker)", 
                                "Web Server Configuration(Apache2, nginx)",
                                "Experience with Ununtu and Debian-based systems",
                                "Version control(Git)"
                            ].map((type, idx) => (
                                typeof type === "string" ? (
                                    <Fade
                                    in={isOpen}
                                    style={{ transformOrigin: "0 0 0" }}
                                    {...(isOpen ? {timeout: timeoutValues[idx]} : {})}

                                    >
                                        <Typography 
                                        id="misc-text"
                                        style={{
                                            marginLeft: "0.5vw"
                                        }}
                                        >    
                                            {type}
                                        </Typography>
                                    </Fade>
                                ) : (
                                    <div>
                                        {type}
                                    </div>
                                )
                            ))}
                        </Box>
                        
                    </AccordionDetails>
                </Accordion>
            </div>
        </section>
    )
};

export default Misc