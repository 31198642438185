import React, { useState, useEffect } from "react"
import 
{ 
    Typography,
    Accordion,
    AccordionSummary,
    AccordionDetails,
    Button,
    Box,
    Grow,
    Fade
 }
from "@mui/material";
import "./Introduction.css";
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import AbstractParticles from "../particles/Style";
import GitHubIcon from '@mui/icons-material/GitHub';
import { profilePic } from "../../utils/img";

function Introduction() {
    const [generalInfoOpen, setGeneralInfoOpen] = useState(false);
    const [carrierInfoOpen, setCarrierInfoOpen] = useState(false);

    useEffect(() => {
        document.title = `Introduction | Portfolio`;
    }, []);
 
    return (
        <div className="intro-outer-container">
        
        
        <div className="intro-particle-container">
            <AbstractParticles />
        </div>
        <div className="poster-container">
            <Typography className="poster-title" variant="h1">Patrik Scherb</Typography>
            <Typography className="poster-text" variant="h5">Full-Stack Web Developer </Typography>
            <div className="poster-button-holder">
                <Button className="poster-github-button" variant="contained">
                    <a 
                    className="poster-github-anchor" 
                    href="https://github.com/shermmminator?tab=repositories"
                    target="_blank"
                    style={{
                        '&hover': {
                           backgroundColor: "back"
                        }
                    }}
                    >
                        <GitHubIcon id="github-icon"/>
                    </a>
                </Button>
                
            </div>
        </div>
        <div className="intro-inner-container">
            <section className="general-info">
                <div className="general-info-container">
                    
                    <Accordion
                    id="intro-accordion"
                    sx={{
                        width: "85%",
                        backgroundColor: "black",
                       
                    }}
                    onChange={() => setGeneralInfoOpen(prev => !prev)}
                    >
                        <AccordionSummary
                        expandIcon={<ExpandMoreIcon style={{color: "white"}}/>}
                        aria-controls="general-information"
                        id="general-introduction"
                        >
                            <Typography 
                            id="introduction-title"
                            variant="h4"
                            >
                                General
                            </Typography>
                        </AccordionSummary>
                        <AccordionDetails>
                            
                                <Box id="box">
                                    <Grow 
                                    in={generalInfoOpen}
                                    style={{ transformOrigin: '0 0 0' }}
                                    {...(generalInfoOpen ? { timeout: 1000 } : {})}

                                    >
                                        <Typography id="title">Introduction</Typography>
                                    </Grow>
                                    <Grow
                                    in={generalInfoOpen}
                                    style={{ transformOrigin: '0 0 0' }}
                                    {...(generalInfoOpen ? { timeout: 1300 } : {})}
                                    >
                                        <Typography id="introduction-text">
                                            Hi there dear reader, welcome to my portfolio website! My name is Patrik Scherb
                                            and I come from Hungary, an aspiring software developer with 2 years of programming experience, specialized in building community-oriented applications.
                                            Proficient in various programming languages and development platforms (Check out the Technologies section for more on this). 
                                            Seeking preferably part-time opportunities that allow for continuous learning and professional growth in a remote and flexible work environment.
                                        </Typography>
                                    </Grow>
                                </Box>
                                <Box id="box">
                                    <Grow
                                    in={generalInfoOpen}
                                    style={{ transformOrigin: '0 0 0' }}
                                    {...(generalInfoOpen ? { timeout: 1600 } : {})}
                                    >
                                        <Typography id="title">Personality Traits</Typography>
                                    </Grow>
                                    <Grow
                                    in={generalInfoOpen}
                                    style={{ transformOrigin: '0 0 0' }}
                                    {...(generalInfoOpen ? { timeout: 1900 } : {})}
                                    >
                                        <Typography id="introduction-text">
                                        I am a dedicated and genuine individual, known for my profound thoughtfulness and authenticity. 
                                        I adapt well to different environments and value the attributes of those I work with, striving to build positive
                                        and productive relationships. I am continuously motivated to learn and apply new technologies, and I am committed to 
                                        creating solutions that benefit the community and support others.
                                        </Typography>
                                    </Grow>
                                </Box>
                                <Box id="box">
                                    <Grow
                                    in={generalInfoOpen}
                                    style={{ transformOrigin: '0 0 0' }}
                                    {...(generalInfoOpen ? { timeout: 2200 } : {})}
                                    >
                                        <Typography id="title">Contact</Typography>
                                    </Grow>
                                    <Grow
                                    in={generalInfoOpen}
                                    style={{ transformOrigin: '0 0 0' }}
                                    {...(generalInfoOpen ? { timeout: 2500 } : {})}
                                    >
                                        <Typography id="introduction-text">
                                        Email: scherb97@gmail.com<br />
                                        Phone: +36307835220 <br />
                                        Currently residing in HU, in 60km proximity from Vienna <br />
                                        I'd prefer not to change locations, so planning to commute
                                        </Typography>
                                    </Grow>
                                </Box>
                            
                        </AccordionDetails>
                    </Accordion>
                </div>
            </section>
            <section className="education-info">
                <div className="education-info-container">
                <Accordion
                    id="intro-accordion"
                    sx={{
                        width: "85%",
                        backgroundColor: "black",
                       
                    }}
                    onChange={() => setCarrierInfoOpen(prev => !prev)}
                    >
                        <AccordionSummary
                        expandIcon={<ExpandMoreIcon style={{color: "white"}}/>}
                        aria-controls="general-information"
                        id="general-itroduction"
                        >
                            <Typography 
                            id="introduction-title"
                            variant="h4"
                            >
                                Education & Carrier
                            </Typography>
                        </AccordionSummary>
                        <AccordionDetails>
                            <Box>
                                <Grow
                                in={carrierInfoOpen}
                                style={{ transformOrigin: '0 0 0' }}
                                {...(carrierInfoOpen ? { timeout: 1000 } : {})}
                                >
                                    <Typography id="introduction-text">
                                        Finished elmementary school in my Hometown, then went on doing the high school in capital of 
                                        my county in Győr, where studied economics and achieved a language examination in german of level 
                                        C1. Started my academical studies abroad on the Vienna University of Economics(WU Wien), that was
                                        composed of account management and private law. I ended up learning IT on numerous platforms at the same time such as <a href="https://tryhackme.com/p/nagaowarrior" alt="tryhackme profile">Tryhackme, </a>
                                        <a href="https://codecademy.com" alt="codecademy profile">Codecademy, </a><a href="https://www.codewars.com/users/shermmminator" alt="codewars profile">Codewars</a> to name a few. 
                                        During this time I had a part time job as a sales person in a fashion store in Austria. Click here to read a short <a href="files/pvh-zeugnis.pdf" target="_blank">promo</a> from previous employers.
                                    </Typography>
                                </Grow>
                            </Box>
                        </AccordionDetails>
                    </Accordion>
                </div>
            </section>
        </div>
    </div>
    )
};

export default Introduction