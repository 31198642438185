import React, { useEffect, useState } from "react";
import "./Technologies.css";
import 
{ 
    Typography,
    Accordion,
    AccordionSummary,
    AccordionDetails,
    Popover,
    Box,
    Grow,
    Fade,
    Collapse
 }
from "@mui/material";
import { Popup } from "semantic-ui-react";
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { SiPython } from "react-icons/si";
import { IoLogoJavascript } from "react-icons/io5";

//custom components
import CSS from "./css/Component";
import HTML from "./html/Component";
import DB from "./db/Component";
import Misc from "./misc/Component";
import 'semantic-ui-css/semantic.min.css'
//
import PopupComp from "./popUpBox/PopupComp";

function Technologies() {
    const [jsAccOpen, setJsAccOpen] = useState(false);
    const [pythonAccOpen, setPythonAccOpen] = useState(false);
    const pythonTimeoutArr = [1000, 1300, 1600, 1900, 2200, 2500, 2800, 3100, 3400, 3700];

    useEffect(() => {
        document.title = `Technologies | Portfolio`
    }, []);

    return (
        <div className="tech-outer-container">
            <div className="tech-inner-container">
                

                    <Typography
                    id="header"
                        >
                        Eager to learn new technologies and adapt to new environments.
                    </Typography>
             
                <section className="python-container">
                    <div className="python-inner-container">
                    <Accordion
                    id="python-accordion"
                    sx={{
                        width: "85%",
                        backgroundColor: "black",
                    }}
                    onChange={() => setPythonAccOpen(prev => !prev)}
                    >
                        <AccordionSummary
                        expandIcon={<ExpandMoreIcon style={{color: "white"}}/>}
                        aria-controls="general-information"
                        id="python-introduction"
                        >
                            <Typography 
                            id="python-title"
                            variant="h4"
                            >
                                Python <SiPython id="python-icon" />
                            </Typography>
                        </AccordionSummary>
                        <AccordionDetails
                        id="accordion-details"
                        >
                            <Box id="box">  
                                {[
                                    <PopupComp 
                                    name="Flask"
                                    description={`Jinja templates, SQLAlchemy, LoginManager, Models, Forms, psycopg2 module`}
                                    isOpen={pythonAccOpen}
                                    timeOut={1000}
                                    />,
                                    <PopupComp 
                                    name="Django"
                                    description={`Templates, Authentication, oAuth, Models, Database Operations`}
                                    isOpen={pythonAccOpen}
                                    timeOut={1300}
                                    />,
                                    "Concurrent Programming", "Functional Programming", "Context Managers", "Unit Testing", "Error Handling", "Collections", "Sets"]
                                    .map((type, idx) => (
                                        typeof type === "string" ? 
                                        (
                                        <>
                                            <Fade
                                            in={pythonAccOpen}
                                            style={{ transformOrigin: '0 0 0' }}
                                            {...(pythonAccOpen ? {timeout: pythonTimeoutArr[idx]} : {})}
                                            >
                                                <Typography
                                                id="introduction-text"
                                                >
                                                    {type}
                                                </Typography>
                                            </Fade>
                                        </>
                                        ) : (
                                        <div> 
                                            {type}
                                        </div>
                                        )
                                    ))}
                            </Box>
                        </AccordionDetails>
                    </Accordion>
                    </div>
                </section>
                <section className="js-container">
                    <div className="js-inner-container">
                    <Accordion
                    id="js-accordion"
                    sx={{
                        width: "85%",
                        backgroundColor: "black"
                    }}
                    onChange={() => setJsAccOpen(prev => !prev)}
                    >
                        <AccordionSummary
                        expandIcon={<ExpandMoreIcon style={{color: "white"}}/>}
                        aria-controls="general-information"
                        id="general-itroduction"
                        >
                            <Typography 
                            id="js-title"
                            variant="h4"
                            >
                                JS <IoLogoJavascript id="js-icon" />
                            </Typography>
                        </AccordionSummary>
                        <AccordionDetails
                        id="accordion-details"
                        style={{
                            display: "inline-flex",
                            
                        }}
                        >
                            <Box id="box">
                                {
                                    [
                                    <PopupComp
                                    name="React JS"
                                    description={`React Router v6, Forms, React Redux, Context Providers, Custom Hooks, React Native`}
                                    isOpen={jsAccOpen}
                                    timeOut={1000}
                                    />,
                                    <PopupComp
                                    name="Angular 17<"
                                    description={`Directives, Routing, Services, Dependency Injection, Class Components`}
                                    isOpen={jsAccOpen}
                                    timeOut={1300}
                                    />,
                                    <PopupComp
                                    name="Express JS"
                                    description={`Express-Session, Passport.js, Persisting Sessions, Middlewares, Express Router`}
                                    isOpen={jsAccOpen}
                                    timeOut={1600}
                                    />,
                                    "TypeScript", "Asynchronous Programming", "Unit Testing", "Promises", "Error Handling", "Node.js"
                                    ].map((type, idx) => (
                                        typeof type === "string" ? (
                                            <Fade
                                            in={jsAccOpen}
                                            style={{ transformOrigin: '0 0 0' }}
                                            {...(jsAccOpen ? { timeout: pythonTimeoutArr[idx] } : {})}
                                            >
                                                <Typography
                                                id="introduction-text"

                                                >
                                                    {type}
                                                </Typography>
                                            </Fade>
                                        ) : (
                                            <div>
                                                {type}
                                            </div>
                                        )
                                    ))
                                    
                                }
                            </Box>
                           
                            
                        </AccordionDetails>
                    </Accordion>
                    </div>
                </section>
                <CSS />
                <HTML />
                <DB />
                <Misc />
            </div>
        </div>   
    )
};

export default Technologies