import React from "react";
import "./Footer.css";
import { Typography } from "@mui/material";
import CopyrightIcon from '@mui/icons-material/Copyright';
import { Outlet } from "react-router";

function Footer() {

    return (
        <>
        
        <div className="footer-container">
            <Typography
            variant="h4"
            id="footer-text"
            >
                All Rights Reserved
                <CopyrightIcon 
                id="copyright-icon"
                />            
            </Typography>
        </div>
        </>
    )

};

export default Footer