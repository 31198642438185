import React, { Component } from "react";
import "./Slider.css";
import CarouselCard from "../carouselcard/carouselCard";
import "swiper/css/bundle";
import 'swiper/css/pagination';
import { Swiper, SwiperSlide } from 'swiper/react';
import { img0, img1, img2, img3 } from "../img";
import { 
  Navigation, 
  Pagination, 
  Scrollbar, 
  A11y, 
} 
from 'swiper/modules';

function SimpleSlider() {
  return (
    <div className="swiper-container">
      <Swiper
      style={{
        "--swiper-pagination-color": "white",
        "--swiper-pagination-bullet-inactive-color": "white"
      }}
      className="contact_swiper"
      slidesPerView={1}
      modules={[Scrollbar, Pagination, Navigation, A11y]}
      spaceBetween={60}
      pagination={{
        clickable: false,
        dynamicBullets: true,  
    }}
      autoplay={{ delay: 4000, pauseOnMouseEnter: true }}
      loop={ true }
      breakpoints={{
          1025: {
              slidesPerView: 1,
              spaceBetween: 30
          },
          768: {
              slidesPerView: 1,
              spaceBetween: 30
          },
          450: {
              slidesPerView: 1,
              spaceBetween: 35
          },
          300: {
              slidesPerView: 1,
              spaceBetween: 20,
              pagination: {
                dynamicBullets: true,
              }
          }
     }} 
      >
        <SwiperSlide>
          <div>
            <CarouselCard 
            img={img0}
            title="eskafashion.com"
            buttonText="Go to website"
            href="https://eskafashion.com"
            text={`
            React.js, 
            Material UI, 
            React Spring,
            Swiper, 
            Fetch API,
            Python Flask`
            }
            
            />
          </div>
        </SwiperSlide>
        <SwiperSlide>
          <div>
          <CarouselCard 
          img={img1}
          title="Gadgetron"
          buttonText="Go to repo"
          href="https://github.com/shermmminator/e-commerse"
          text={`
          Prototype solution to a project from
          codecademy utilizing the 
          PERN stack and MVC architecture
          `}
          />
          </div>
        </SwiperSlide >
        <SwiperSlide>
          <div>
          <CarouselCard 
          img={img2}
          title="Ravenous"
          buttonText="Show source"
          href="https://github.com/shermmminator/ravenous"
          text={`
          Solution to the codecademy project
          ravenous. This project uses React router,
          React Redux, Redux Store, Fetch API
          `}
          />
          </div>
        </SwiperSlide>
        <SwiperSlide>
          <div>
          <CarouselCard 
          img={false}
          title="Witcher Bestiary"
          buttonText="Not Available"
          href="#"
          text={`
          My humble tribute to the greatest
          game ever made, incorporating React, Python Flask,
          PostgreSQL and Semantic UI.
          `}
          video="../img/witcher_bestiary_demo.mp4"
          />
          </div>
        </SwiperSlide>
        <SwiperSlide>
          <div>
          <CarouselCard 
          img={img3}
          title="Django Delight"
          buttonText="Go to website"
          href="https://django-delight.com"
          text={`
          By far my most lengthy to finish project.
          It uses Python Django with Postgres models, google
          oAuth and React with MUI as design elements.
          `}
          video={false}
          />
          </div>
        </SwiperSlide>
      </Swiper>
    </div>
  )
}

export default SimpleSlider