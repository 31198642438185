import React, { useEffect, useState, useRef } from "react";
import "./Contact.css";
import { Typography, Button, Fade, Grow, Collapse } from "@mui/material";
// import { textA, textB } from "./text_dir/textToType";

function Contact() {

    const [contactText, setContactText] = useState("Please contact me by the following email address");
    const [currentIndex, setCurrentIndex] = useState(0);
    const [typedtext, setTypedText] = useState("");
    const typingSpeed = 50;
    /// DYNAMICALLY CHANGING HEIGHT CONF
    const heightRef = useRef(0);
    const [maxHeight, setMaxHeight] = useState(0);

    useEffect(() => {
        if(heightRef.current) {
            setMaxHeight(heightRef.current.scrollHeight);
        }
    }, [typedtext])

    useEffect(() => {
        document.title = `Contact | Portfolio`
    }, []);

    const maxHeightConfig = {
        maxHeight: maxHeight ? `${maxHeight}px` : `0px`
    };
    ////
  
    useEffect(() => {
        if(currentIndex < contactText.length) {
            const timeoutId = setTimeout(() => {
                setTypedText(prev => prev + contactText[currentIndex]);
                setCurrentIndex(prev => prev + 1);

            }, typingSpeed)
            return () => clearTimeout(timeoutId);
        }

    }, [currentIndex]);

    const popupComponent = (
        <Grow
            className="popup-container"
            in={currentIndex === contactText.length}
            style={{
                display: "flex",
                justifyContent: "center",
            }}
            
            >
            <div
            className="popup-inner-container"
            >
                <Typography id="email-address">scherb97@gmail.com</Typography>
            </div>
        </Grow>
    )

    return (
        <div className="contact-outer-container" ref={heightRef} style={maxHeightConfig}>
            <div className="contact-inner-container">
                <div className="contact-title-holder">
                    <Typography id="contact-title" variant="h3">
                        {typedtext}
                    </Typography>
                </div>
                <section className="contact-email-holder">
                    {popupComponent}
                </section>
            </div>
        </div>
    )
};

export default Contact