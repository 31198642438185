import React, { useState } from "react";
import "./Component.css";
import
{ 
    Typography,
    Accordion,
    AccordionSummary,
    AccordionDetails,
    Popover,
    Fade,
    Box
}
from "@mui/material";
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { IoLogoHtml5 } from "react-icons/io";

function HTML() {
    const [isOpen, setIsOpen] = useState(false);
    const timeoutValues = [1000, 1300, 1900]

    return (
                <section className="html-container">
                    <div className="html-inner-container">
                        <Accordion
                        id="html-accordion"
                        sx={{
                            width: "85%",
                            backgroundColor: "black",
                        }}
                        onChange={() => setIsOpen(prev => !prev)}
                        >
                            <AccordionSummary
                            expandIcon={<ExpandMoreIcon style={{color: "white"}}/>}
                            aria-controls="general-information"
                            id="html-introduction"
                            >
                                <Typography 
                                id="html-title"
                                variant="h4"
                                >
                                    HTML <IoLogoHtml5 id="html-logo"/>
                                </Typography>
                            </AccordionSummary>
                            <AccordionDetails
                            style={{
                                display: "flex"
                            }}
                            >
                                <Box id="box">
                                    {["Tables", "Semantic HTML", "Form Validation"].map((type, idx) => (
                                        <Fade
                                        in={isOpen}
                                        style={{ transformOrigin: "0 0 0" }}
                                        {...(isOpen ? { timeout: timeoutValues[idx] } : {})}
                                        >
                                            <Typography 
                                            id="html-text"
                                            style={{
                                                marginLeft: "0.5vw"
                                            }}
                                            >    
                                                {type}
                                            </Typography>
                                        </Fade>
                                    ))}
                                </Box>
                            </AccordionDetails>
                        </Accordion>
                    </div>
                </section>
    )
}

export default HTML