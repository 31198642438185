import React from "react";
import 
{   
    Box, 
    AppBar, 
    Toolbar, 
    IconButton, 
    Drawer,
    List,
    ListItem,
    Typography,
    ListItemButton,
    Grow,
}
from "@mui/material";
import MenuIcon from '@mui/icons-material/Menu';
import "./Header.css"
import { useState } from "react";
import { Link, NavLink } from "react-router-dom";
import FaceSharpIcon from '@mui/icons-material/FaceSharp';
import { menuImg, micImg } from "../../utils/img";

function Header() {

    const [open, setOpen] = useState(false);
    const timeoutOptions = [1000, 1300, 1600, 1900]

    const toggleDrawerOpen = () => {
        setOpen(true);
    };

    const toggleDrawerClose = () => {
        setOpen(false);
    }

    const drawer = (
        <Box
        sx={{
            width: 250,
            backgroundColor: "black",
            height: "100%"
        }}
        role="presentation"
        onClick={toggleDrawerClose}
        onKeyDown={toggleDrawerClose}
        >
            <List>
                {["introduction", "technologies", "references", "contact"].map((path, index) => (
                    <Box id="link-path-container">
                        <ListItem key={index} disablePadding>
                            <ListItemButton sx={{ 
                                display: "flex",
                                justifyContent: "center"
                                }}>
                                <Grow
                                in={open}
                                style={{ transformOrigin: '0 0 0' }}
                                {...(open ? { timeout: timeoutOptions[index] } : {})}
                                >
                                    <NavLink 
                                    to={path === "introduction" ? `/` : `/${path}`}
                                    className={({isActive}) => isActive ? "navtag-active" : "navtag"}
                                    >
                                            <Typography id="menu-path-title">
                                                {path}
                                            </Typography>
                                            
                                    </NavLink>
                                </Grow>
                            </ListItemButton>
                        </ListItem>
                    </Box>
                ))}
            </List>
        </Box>
    )

    return (
        <>
            <div className="header-container"> 
                <Box 
                sx={{
                    flexGrow: 1,
                    bgcolor: "black"
                }}
                >
                    <Toolbar>
                        <IconButton
                        size="large"
                        >
                            <img 
                            id="menu-icon"
                            style={{
                                color: "white",
                            }}
                            src={menuImg}
                            onClick={toggleDrawerOpen}
                            />   
                        </IconButton>
                        <IconButton
                        id="logo-icon-button"
                        size="large"
                        >
                            <FaceSharpIcon
                            id="face-icon"
                            style={{
                                color: "white",
                                
                            }}
                            />
                            <Typography
                            id="logo-text"
                            style={{
                                color: "white",
                                fontFamily: "Anton"
                            }}
                            variant="h3"
                            >   
                                PORTF.
                            </Typography>
                        </IconButton>
                    </Toolbar>
                </Box>
                <Drawer
                anchor="left"
                open={open}
                onClose={toggleDrawerClose}
                >
                    {drawer}
                </Drawer>
            </div>
           
        </>
    )

    
};

export default Header