import React, { useEffect, useState, useRef } from "react";
import "./References.css";
import { Typography, Button } from "@mui/material";
import { textA, textB } from "./text_dir/textToType";
import SimpleSlider from "./simpleslider/Slider";

function References() {

    const typingSpeed = 50;
   
    /////TEXT A
    const [currentIndexA, setCurrentIndexA] = useState(0);
    const [typedTextA, setTypedTextA] = useState("");

    useEffect(() => {
        document.title = `References | Portfolio`
    }, []);

    useEffect(() => {
        if(currentIndexA < textA.length) {
            const timeoutId = setTimeout(() => {
                setTypedTextA(prev => prev + textA[currentIndexA]);
                setCurrentIndexA(prev => prev + 1);

            }, typingSpeed);

            return () => clearTimeout(timeoutId);
        }
    }, [currentIndexA]);

    /////////
    ////CHANGE DIV HEIGHT DYNAMICALLY

    const [contentHeight, setContentHeight] = useState(0);
    const typedTextHolderRef = useRef(null);
    const isTextFullyTyped = currentIndexA === textA.length;

    useEffect(() => {
        
        if (typedTextHolderRef.current) {
          setContentHeight(typedTextHolderRef.current.scrollHeight);
        }
    }, [typedTextA]);

    const typedTextHolderStyle = {
        maxHeight: contentHeight ? `${contentHeight}px` : '0px',
    };

    //////

    return (
        <>
            <div className="ref-outer-container">
                <div 
                style={typedTextHolderStyle}
                className="ref-inner-container"
                ref={typedTextHolderRef}
                >
                    <section className="personal-site-section">
                        <div 
                        className="typedtext-holder"
                        >
                            <Typography id="ref-text" variant="h4">
                                {typedTextA}
                            </Typography>
                        </div>
                        <div className="carousel-container">
                            {isTextFullyTyped && <SimpleSlider />}
                        </div>
                    </section >
                    
                </div>
            </div>
      
        </>
    )

};

export default References