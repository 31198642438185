import React, { useState } from "react";
import "./Component.css";
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { FaDatabase } from "react-icons/fa6";
import
{ 
    Typography,
    Accordion,
    AccordionSummary,
    AccordionDetails,
    Popover,
    Box,
    Fade
}
from "@mui/material";

function DB () {
    const [isOpen, setIsOpen] = useState(false);
    const timeoutValues = [1000, 1300, 1600, 1900, 2200];

    return (
        <section className="db-container">
            <div className="db-inner-container">
                <Accordion
                id="db-accordion"
                sx={{
                    width: "85%",
                    backgroundColor: "black",
                }}
                onChange={() => setIsOpen(prev => !prev)}
                >
                    <AccordionSummary
                    expandIcon={<ExpandMoreIcon style={{color: "white"}}/>}
                    aria-controls="general-information"
                    id="db-introduction"
                    >
                        <Typography 
                        id="db-title"
                        variant="h4"
                        >
                            Relational Databases <FaDatabase id="db-logo"/>
                        </Typography>
                    </AccordionSummary>
                    <AccordionDetails
                    style={{
                        display: "flex"
                    }}
                    >
                        <Box id="box">
                            {["MySQL", "PostgreSQL", "PostgreSQL integration using pg Module in Node.js", "Flask SQL Module", "Django Models"]
                            .map((type, idx) => (
                            <Fade
                            in={isOpen}
                            style={{ transformOrigin: "0 0 0"}}
                            {...(isOpen ? {timeout: timeoutValues[idx]} : {})}
                            >
                                <Typography 
                                id="db-text"
                                style={{
                                    marginLeft: "0.5vw"
                                }}
                                >    
                                    {type}
                                </Typography>
                            </Fade>
                            ))}
                        </Box>
                        
                    </AccordionDetails>
                </Accordion>
            </div>
        </section>
    )
};

export default DB