import React, { useCallback } from "react";
import Particles from "react-tsparticles";
import { loadFull } from "tsparticles";
import { stylingOptions, dottedStyle } from "./config";
import "./Style.css";

function AbstractParticles () {

    const particlesInit = useCallback(async engine => {
        console.log(engine);
        await loadFull(engine);
    }, []);
    
    const particlesLoaded = useCallback(async container => {
        await console.log(container);
    }, []);

    return (
        <div id="particles-container">
        <Particles
        id="tsparticles"
        style={{
            postition: "absolute",
            zIndex: "1",
            
        }}
        init={particlesInit}
        loaded={particlesLoaded}
        params={dottedStyle}
        >
        </Particles>
    </div>
  );
};

export default AbstractParticles