import React, { useState } from "react";
import "./Component.css";
import
{ 
    Typography,
    Accordion,
    AccordionSummary,
    AccordionDetails,
    Popover,
    Fade,
    Box
}
from "@mui/material";
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { IoLogoCss3 } from "react-icons/io";


function CSS () {
    const [isOpen, setIsOpen] = useState(false);
    const timeoutValues = [1000, 1300, 1600, 1900, 2200, 2500]

    return(
                <section className="css-container">
                    <div className="css-inner-container">
                        <Accordion
                        id="css-accordion"
                        sx={{
                            width: "85%",
                            backgroundColor: "black",
                        }}
                        onChange={() => setIsOpen(prev => !prev)}
                        >
                            <AccordionSummary
                            expandIcon={<ExpandMoreIcon style={{color: "white"}}/>}
                            aria-controls="general-information"
                            id="css-introduction"
                            >
                                <Typography 
                                id="css-title"
                                variant="h4"
                                >
                                    CSS <IoLogoCss3 id="css-logo"/>
                                </Typography>
                            </AccordionSummary>
                            <AccordionDetails
                            style={{
                                display: "flex"
                            }}
                            >
                                <Box
                                id="box"
                                >
                                    {["Responsive Webdesign(Grid, Flexbox)", "Animations", "Transitions", "Functions", "Variables", "SASS"]
                                    .map((type, idx) => (
                                        <Fade
                                        in={isOpen}
                                        style={{ transformOrigin: "0 0 0" }}
                                        {...(isOpen ? {timeout: timeoutValues[idx]} : {})}
                                        >
                                            <Typography 
                                            id="introduction-text"
                                            style={{
                                                marginLeft: "0.5vw"
                                            }}
                                            >    
                                                {type}
                                            </Typography>
                                        </Fade>
                                    ))}

                                </Box>

                            </AccordionDetails>
                        </Accordion>
                    </div>
                </section>
    )
};

export default CSS