import React, { useEffect, useState } from "react"
import { Button, Typography } from "@mui/material"
import "./carouselCard.css"

function CarouselCard({ img, title, text, buttonText, href, video }) {
    
    const [isHighlighted, setIsHighlighted] = useState(false);

    return (
        <div 
        className={`card-container ${isHighlighted ? "container-highlighted" : ""}`}
        onMouseEnter={() => setIsHighlighted(true)}
        onMouseLeave={() => setIsHighlighted(false)}
        onTouchStart={() => setIsHighlighted(true)}
        onTouchEnd={() => setIsHighlighted(false)}
        >
            <div className="card-inner-container">
                {img ? (
                    <>
                        <img 
                        id="reference-img"
                        src={img}
                        />
                    </>
                ) : (
                    <>
                        <video
                        id="reference-video"
                        autoPlay
                        muted
                        loop
                        >
                            <source src={require("../img/witcher_bestiary_demo.mp4")} type="video/mp4" />
                        </video>
                    </>
                )}
                <section className="card-text-holder">
                    <Typography
                    id="card-title"
                    variant="h2"
                    
                    >
                        {title}
                    </Typography>
                    <Typography
                    id="card-text"
                    >   
                    {text}
                    </Typography>
                </section>
                <div className="card-button-holder">
                    <Button
                    id="card-button"
                    variant="contained"
                    >
                    <a 
                    style={{
                        textDecoration: "none",
                        color: "white",
                        width: "100%",
                        height: "100%",
                        display: "flex",
                        flexDirection: "center",
                        alignItems: "center"
                    }}
                    href={href}
                    target="_blank"
                    >
                        {buttonText}
                    </a>
                    </Button>
                </div>
            </div>
        </div>
    )

};

export default CarouselCard