import "./PopupComp.css";
import { Popup } from "semantic-ui-react";
import { Typography, Grow, Fade } from "@mui/material";
import { exclam } from "../../../utils/img";

function PopupComp({ name, description, isOpen, timeOut }) {
    const customStyle = {
        borderRadius: "5px",
        fontSize: "0.8vw",
        display: "inlline-flex",
        // background: "linear-gradient(90deg, rgba(2, 0, 36, 1) 0%, rgba(9, 15, 121, 1) 52%, rgba(18, 73, 120, 1) 100%)",
        
        width: "100%",
        height: "100%",
        border: "1px solid gray",
        
    }

    const trigger = (
        <Fade
        in={isOpen}
        {...(isOpen ? {timeout: timeOut} : {})}
        >
            <div
            className="trigger-box"
            >
                <Typography
                className="tech-name"
                >
                    {name ? `${name}` : "placeholder"}
                </Typography>
                <img 
                id="trigger-img"
                alt="excalamtion-mark"
                src={exclam}
                />
            </div>
        </Fade>
    );

    const content = (
        <div className="popup-container">
            <Typography
            className="hidden-text"
            >
                {description ? `${description}` : "Lorem ipsum, lorem ipsum, lorem ipsum, lorem ipsum, lorem ipsum, lorem ipsum, lorem ipsum"}
            </Typography>
        </div>
    );

    return (
        <Popup
        basic
        trigger={trigger}
        content={content}
        style={customStyle}
        >

        </Popup>
    )
};

export default PopupComp;