import logo from './logo.svg';
import { HashRouter, Routes, Route, BrowserRouter } from 'react-router-dom';
// custom components
import Header from './components/header/Header';
import Introduction from './components/introduction/Introduction';
import Technologies from './components/technologies/Technologies';
import References from './components/references/References';
import Contact from './components/contact/Contact';
import Footer from './components/footer/Footer';
//
//custom fonts
import './fonts/Roboto.css';
import './fonts/Lobster.css';
import './fonts/Quicksand.css';
import './fonts/Anton.css';
//
import AbstractParticles from './components/particles/Style';

function App() {
  return (
      <HashRouter>
        <Header />
        <Routes>
          
            <Route path='/' element={<Introduction />}></Route>
            <Route path='/technologies' element={<Technologies />} ></Route>
            <Route path='/references' element={<References />}></Route>
            <Route path='/contact' element={<Contact />}></Route>
            
        </Routes>
        <Footer />
      </HashRouter>
  );
}

export default App;
